import React, { useEffect, useState } from "react";
import { cfRankColor } from "../utils/constants";
import { UserData, UserStats } from "../utils/types";

function Sidebar(props: {
  userData: UserData;
  userStats: UserStats;
  filters: Array<string>;
  setFilters: (data: Array<string>) => void;
  filterType: boolean;
  setFilterType: (data: boolean) => void;
}) {
  const {
    userData,
    userStats,
    filters,
    setFilters,
    filterType,
    setFilterType,
  } = props;

  const tags = [
    "2-sat",
    "binary search",
    "bitmasks",
    "brute force",
    "chinese remainder theorem",
    "combinatorics",
    "constructive algorithms",
    "data structures",
    "dfs and similar",
    "divide and conquer",
    "dp",
    "dsu",
    "expression parsing",
    "fft",
    "flows",
    "games",
    "geometry",
    "graph matchings",
    "graphs",
    "greedy",
    "hashing",
    "implementation",
    "interactive",
    "math",
    "matrices",
    "meet-in-the-middle",
    "number theory",
    "probabilities",
    "schedules",
    "shortest paths",
    "sortings",
    "string suffix structures",
    "strings",
    "ternary search",
    "trees",
    "two pointers",
  ];

  const cntags = [
    "2-sat 2-适定性问题",
    "binary search 二分查找",
    "bitmasks 位运算",
    "brute force 暴力",
    "chinese remainder theorem 中国剩余定理",
    "combinatorics 排列组合",
    "constructive algorithms 构造算法",
    "data structures 数据结构",
    "dfs and similar 深度优先搜索",
    "divide and conquer 分治",
    "dp 动态规划",
    "dsu 启发式",
    "expression parsing 表达式求值",
    "fft 快速傅立叶变换",
    "flows 流程",
    "games 游戏",
    "geometry 计算几何",
    "graph matchings 图匹配",
    "graphs 图论",
    "greedy 贪心",
    "hashing 哈希",
    "implementation 实现",
    "interactive 交互",
    "math 数学",
    "matrices 矩阵",
    "meet-in-the-middle 中间相遇",
    "number theory 数论",
    "probabilities 概率",
    "schedules 安排",
    "shortest paths 最短路径",
    "sortings 排序",
    "string suffix structures 字符串后缀结构",
    "strings 字符串",
    "ternary search 三分查找",
    "trees 树",
    "two pointers 双指针",
  ];

  const handleToggleText = (text: string) => {
    console.log(filters);
    if (filters.includes(text)) {
      // If the text is already in the array, remove it
      const updatedFilters = filters.filter((item) => item !== text);
      setFilters(updatedFilters);
      localStorage.setItem("filters", JSON.stringify(updatedFilters));
    } else {
      // If the text is not in the array, add it
      setFilters([...filters, text]);
      localStorage.setItem("filters", JSON.stringify([...filters, text]));
    }
  };
  useEffect(() => {
    let tagVal: boolean =
      localStorage.getItem("filterType") === "true" ?? false;
    setFilterType(tagVal);
  }, []);

  return (
    <div>
      <input
        className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        checked={filterType}
        onChange={(e) => {
          setFilterType(e.target.checked);
          localStorage.setItem(
            "filterType",
            e.target.checked ? "true" : "false"
          );
        }}
        // onChange={(e) => setFilterType(e.target.checked)}
      />

      <label className="inline pl-[0.15rem] hover:cursor-pointer text-gray-200 text-lg mr-1">
        Filter Logic 多项选择筛选方法 : {filterType ? "AND 且" : "OR 或"}
      </label>

      <div className="border-2 w-full rounded-3xl flex justify-between my-2">
        <div className="text-sm flex flex-wrap justify-items-start m-3 content-center">
          {tags.map((text, idx) => {
            return (
              <Button
                key={idx}
                text={cntags[idx]}
                isTextInArray={filters.includes(text)}
                onClick={() => handleToggleText(text)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Button(props: {
  text: string;
  isTextInArray: boolean;
  onClick: () => void;
}) {
  const buttonCss = props.isTextInArray
    ? "bg-green-700/[0.9] border text-gray-100 hover:text-white py-1 px-2 m-1 rounded-full align-middle"
    : "bg-aCodeBlue/[0.9] border text-gray-100 hover:text-white py-1 px-2 m-1 rounded-full align-middle";
  return (
    <button onClick={props.onClick} className={buttonCss}>
      {props.text}
    </button>
  );
}

export default Sidebar;
