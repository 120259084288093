import { useEffect, useState } from "react";
import "./footer.css";
import FooterCard from "./FooterCard";
import { constants } from "../utils/constants";

const Footer: React.FC = () => {
  const [counter, setCounter] = useState(0);

  const getData = async () => {
    const response = await fetch(`${constants.api}/analysis`);
    if (response.status == 200) {
      const data = await response.json();
      // console.log(typeof data.data[0].hits);
      setCounter(data.data.hits);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="footer">
    <span className="bottom">
    <span>DQW and</span>
    <img src="./assets/TextLogo.png" alt="ACD Ladders" />
    <span>Team with ❤</span>
    </span>
    </div>
  );
};

export default Footer;
