import React from "react";
import "./socialBar.css";

const SocialBar: React.FC = () => {
  return (
    <div className="social-bar">
    </div>
  );
};

export default SocialBar;
